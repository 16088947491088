import React from "react";

const About = () => {
  return (
    <div
      name="about"
      className="w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About
          </p>
        </div>

        <p className="text-xl mt-20">
          Hello, I'm Giray Eral. After graduating from Hacettepe University with
          a degree in Business Administration in English and gaining extensive
          experience in the field of Human Resources in various respected
          companies, I decided to shift my career towards technology. My journey
          in self-education started with mastering web technologies such as
          HTML, CSS, JavaScript, and React, fuelled by a high level of
          enthusiasm and energy.
        </p>

        <br />

        <p className="text-xl">
          Having relocated to Prague, where I currently reside, I hold an
          established residence and work permit. Additionally, my proficiency in
          English at a C1 level aids in seamless communication and understanding
          in the global workspace. The time I've spent working on and learning
          from my own projects has prepared me to face new challenges, and I am
          eager to bring this readiness to new collaborations. I am committed to
          continual learning and development, and I look forward to evolving my
          skills further in the future.
        </p>
      </div>
    </div>
  );
};

export default About;
