import React, { useState } from "react";
import { FaBars} from "react-icons/fa";
import { Link, useLocation, NavLink } from "react-router-dom";
// import { Link } from "react-scroll";

const NavBar = () => {
  const [nav, setNav] = useState(false);
  const location = useLocation();

  const links = [
    {
      id: 1,
      link: "home",
    },
    {
      id: 2,
      link: "about",
    },
    {
      id: 3,
      link: "portfolio",
    },
    {
      id: 4,
      link: "experience",
    },
    {
      id: 5,
      link: "contact",
    },
  ];

  const closeMenu = () => {
    setNav(false);
  };

  return (
    <div className="flex justify-between items-center w-full h-20 px-4 text-white bg-black fixed">
      <div>
        <h1 className="text-5xl font-signature ml-2">Giray</h1>
      </div>

      <ul className="hidden md:flex">
        {links.map(({ id, link }) => (
          <li
            key={id}
            className={`px-4 cursor-pointer capitalize font-medium hover:scale-105 duration-200 ${
              location.pathname === `/${link}` ||
              (location.pathname === "/" && link === "home")
                ? "text-white"
                : "text-gray-500"
            }`}
          >
            <Link to={`/${link === "home" ? "" : link}`}>{link}</Link>
          </li>
        ))}
      </ul>

      <div
        onClick={() => setNav(!nav)}
        className="cursor-pointer pr-4 z-10 text-gray-500 md:hidden"
      >
        {nav ? <FaBars size={30} /> : <FaBars size={30} />}
      </div>

      {nav && (
        <ul className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-800 ">
          <li
            onClick={closeMenu}
            className="self-end cursor-pointer pr-8 pt-8 text-4xl"
          >
            X
          </li>

          {links.map(({ id, link }) => (
            <li
              key={id}
              className={`px-4 cursor-pointer capitalize py-6 text-4xl ${
                location.pathname === `/${link}` ||
                (location.pathname === "/" && link === "home")
                  ? "text-white"
                  : "text-gray-500"
              }`}
            >
              <NavLink
                to={link}
                activeClassName="text-white"
                onClick={closeMenu}
              >
                {link}
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NavBar;
