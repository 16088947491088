// Main.js
import React from 'react';
import Home from './Home';
import About from './About';
import Portfolio from './Portfolio';
import Experience from './Experience';
import Contact from './Contact';

const Main = () => {
  return (
    <>
      <Home />
      <About />
      <Portfolio />
      <Experience />
      <Contact />
    </>
  );
};

export default Main;
