import React from "react";
import fakeStore from "../assets/portfolio/fakeStore.jpg";
import incomeTracker from "../assets/portfolio/incomeTracker.jpg";
import movieApp from "../assets/portfolio/movieApp.jpg";
import reactWeather from "../assets/portfolio/reactWeather.jpg";
import spotifyClone from "../assets/portfolio/spotifyClone.jpg";

const Portfolio = () => {
  const portfolios = [
    {
      id: 1,
      src: incomeTracker,
      demo: "https://react-income-tracker.netlify.app/",
      github: "https://github.com/girayeral/react-income-tracker",
    },
    {
      id: 2,
      src: reactWeather,
      demo: "",
      github: "https://github.com/girayeral/react-weather-app",
    },
    {
      id: 3,
      src: fakeStore,
      demo: "https://react-fakestore-app.netlify.app/",
      github: "https://github.com/girayeral/react-ecommerce",
    },
    {
      id: 4,
      src: movieApp,
      demo: "https://react-movie-app-carousel.netlify.app/",
      github: "https://github.com/girayeral/react-movie-app",
    },
    {
      id: 5,
      src: spotifyClone,
      demo: "https://spotify-react-clone-app.netlify.app/",
      github: "https://github.com/girayeral/react-spotify-clone",
    },
  ];
  return (
    <div
      name="portfolio"
      className="bg-gradient-to-b from-black to-gray-800 text-white md:h-screen"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            Portfolio
          </p>
          <p className="py-6">Check out some of my work right here</p>
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0">
          {portfolios.map(
            (
              portfolio // portfolio is added here
            ) => (
              <div
                key={portfolio.id}
                className="shadow-md shadow-gray-600 rounded-lg"
              >
                <img
                  src={portfolio.src}
                  alt=""
                  className="rounded-md duration-200 hover:scale-105"
                />
                <div className="flex items-center justify-center">
                  <button
                    className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105"
                    onClick={() => window.open(portfolio.demo, "_blank")}
                  >
                    Demo
                  </button>
                  <button
                    onClick={() => window.open(portfolio.github, "_blank")}
                    className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105"
                  >
                    Code
                  </button>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
